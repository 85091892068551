.contact_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  .container form {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
 


  .container input,
  .container textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);

  }
  
  /*==== Media queries (medium devices) ==== */
@media screen and (max-width: 1024px) {
   .container {
    grid-template-columns: 1fr;
    gap: 2rem;
   }
     }
     
     /*==== Media queries (small devices) ==== */
     @media screen and (max-width: 600px) {
        .container {
            width: var(--container-width-sm);
           }
     }
 
  
